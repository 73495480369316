import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout.jsx";
import SEO from "../components/seo.jsx";
import ArticleThumb from "../components/common/articleThumb.jsx";

import styles from "./../styles/modules/blog.module.scss";

const BlogPage = ({ data }) => {
  const articles = data.blog.edges;
  return (
    <Layout header="simple" headerTitle="Some of my interesting thoughts.">
      <SEO
        title="Blog"
        keywords={[
          `web`,
          `developer`,
          "frontend",
          "front end",
          "user experience",
          "ux",
          "designer",
          "javascript developer",
          "application",
          "react"
        ]}
      />
      <main className={styles.main}>
        {articles.map((article, index) => (
          <ArticleThumb
            className={styles.article}
            article={article.node}
            key={index}
          />
        ))}
      </main>
    </Layout>
  );
};

export const staticQuery = graphql`
  {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog)/.*.md$/" } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            title
            keywords
            description
            date
            blogThumb
          }
        }
      }
    }
  }
`;

export default BlogPage;
