import React from "react";
import { Link } from "gatsby";

import styles from "./../../styles/modules/articleThumb.module.scss";

const ArticleThumb = ({ article, className }) => (
  <div className={`${styles.article} ${className}`}>
    <Link to={`/blog${article.fields.slug}`}>
      <img
        src={article.frontmatter.blogThumb}
        alt={article.frontmatter.title}
      />
      <div className={styles.date}>{article.frontmatter.date}</div>
      <h3>{article.frontmatter.title}</h3>
    </Link>
  </div>
);

export default ArticleThumb;
